// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-add-holidays-js": () => import("./../../../src/pages/add-holidays.js" /* webpackChunkName: "component---src-pages-add-holidays-js" */),
  "component---src-pages-all-reviews-js": () => import("./../../../src/pages/all-reviews.js" /* webpackChunkName: "component---src-pages-all-reviews-js" */),
  "component---src-pages-analytics-js": () => import("./../../../src/pages/analytics.js" /* webpackChunkName: "component---src-pages-analytics-js" */),
  "component---src-pages-available-teacher-js": () => import("./../../../src/pages/availableTeacher.js" /* webpackChunkName: "component---src-pages-available-teacher-js" */),
  "component---src-pages-book-a-lesson-copy-js": () => import("./../../../src/pages/book-a-lesson copy.js" /* webpackChunkName: "component---src-pages-book-a-lesson-copy-js" */),
  "component---src-pages-book-a-lesson-js": () => import("./../../../src/pages/book-a-lesson.js" /* webpackChunkName: "component---src-pages-book-a-lesson-js" */),
  "component---src-pages-book-a-lesson-new-js": () => import("./../../../src/pages/book-a-lesson-new.js" /* webpackChunkName: "component---src-pages-book-a-lesson-new-js" */),
  "component---src-pages-book-a-lesson-slot-fix-js": () => import("./../../../src/pages/book-a-lesson-slot-fix.js" /* webpackChunkName: "component---src-pages-book-a-lesson-slot-fix-js" */),
  "component---src-pages-booking-a-lesson-old-js": () => import("./../../../src/pages/booking-a-lesson-old.js" /* webpackChunkName: "component---src-pages-booking-a-lesson-old-js" */),
  "component---src-pages-booking-checkout-js": () => import("./../../../src/pages/booking-checkout.js" /* webpackChunkName: "component---src-pages-booking-checkout-js" */),
  "component---src-pages-booking-slots-js": () => import("./../../../src/pages/booking-slots.js" /* webpackChunkName: "component---src-pages-booking-slots-js" */),
  "component---src-pages-booking-teacher-js": () => import("./../../../src/pages/booking-teacher.js" /* webpackChunkName: "component---src-pages-booking-teacher-js" */),
  "component---src-pages-cancel-js": () => import("./../../../src/pages/cancel.js" /* webpackChunkName: "component---src-pages-cancel-js" */),
  "component---src-pages-confirm-booking-js": () => import("./../../../src/pages/confirm-booking.js" /* webpackChunkName: "component---src-pages-confirm-booking-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-datepicker-js": () => import("./../../../src/pages/datepicker.js" /* webpackChunkName: "component---src-pages-datepicker-js" */),
  "component---src-pages-deleteaccount-js": () => import("./../../../src/pages/deleteaccount.js" /* webpackChunkName: "component---src-pages-deleteaccount-js" */),
  "component---src-pages-dispute-js": () => import("./../../../src/pages/dispute.js" /* webpackChunkName: "component---src-pages-dispute-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-holidays-js": () => import("./../../../src/pages/holidays.js" /* webpackChunkName: "component---src-pages-holidays-js" */),
  "component---src-pages-index-bk-js": () => import("./../../../src/pages/index_bk.js" /* webpackChunkName: "component---src-pages-index-bk-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-level-js": () => import("./../../../src/pages/level.js" /* webpackChunkName: "component---src-pages-level-js" */),
  "component---src-pages-meet-js": () => import("./../../../src/pages/meet.js" /* webpackChunkName: "component---src-pages-meet-js" */),
  "component---src-pages-my-classes-copy-js": () => import("./../../../src/pages/my-classes copy.js" /* webpackChunkName: "component---src-pages-my-classes-copy-js" */),
  "component---src-pages-my-classes-js": () => import("./../../../src/pages/my-classes.js" /* webpackChunkName: "component---src-pages-my-classes-js" */),
  "component---src-pages-my-disputes-js": () => import("./../../../src/pages/my-disputes.js" /* webpackChunkName: "component---src-pages-my-disputes-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-payment-failed-js": () => import("./../../../src/pages/payment-failed.js" /* webpackChunkName: "component---src-pages-payment-failed-js" */),
  "component---src-pages-payments-js": () => import("./../../../src/pages/payments.js" /* webpackChunkName: "component---src-pages-payments-js" */),
  "component---src-pages-preferences-js": () => import("./../../../src/pages/preferences.js" /* webpackChunkName: "component---src-pages-preferences-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-profiles-js": () => import("./../../../src/pages/profiles.js" /* webpackChunkName: "component---src-pages-profiles-js" */),
  "component---src-pages-report-card-js": () => import("./../../../src/pages/report-card.js" /* webpackChunkName: "component---src-pages-report-card-js" */),
  "component---src-pages-review-js": () => import("./../../../src/pages/review.js" /* webpackChunkName: "component---src-pages-review-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/SignUp.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-teacher-js": () => import("./../../../src/pages/teacher.js" /* webpackChunkName: "component---src-pages-teacher-js" */),
  "component---src-pages-teacher-schedule-js": () => import("./../../../src/pages/teacher-schedule.js" /* webpackChunkName: "component---src-pages-teacher-schedule-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-terms-and-conditions-page-js": () => import("./../../../src/pages/terms-and-conditions-page.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-page-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-viewdispute-js": () => import("./../../../src/pages/viewdispute.js" /* webpackChunkName: "component---src-pages-viewdispute-js" */),
  "component---src-pages-wallet-payment-js": () => import("./../../../src/pages/wallet-payment.js" /* webpackChunkName: "component---src-pages-wallet-payment-js" */),
  "component---src-pages-wallet-transactions-js": () => import("./../../../src/pages/wallet-transactions.js" /* webpackChunkName: "component---src-pages-wallet-transactions-js" */)
}

