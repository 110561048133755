import 'firebase/auth'

const config = {
    apiKey: "AIzaSyDF-jRSNiq0irfPEjs25VhaUxAlppVPAyo",
    authDomain: "islamonlineapp.firebaseapp.com",
    projectId: "islamonlineapp",
    storageBucket: "islamonlineapp.appspot.com",
    messagingSenderId: "256681161941",
    appId: "1:256681161941:web:e27a99e2f9bea6eaaa5e5c",
    measurementId: "G-62SMPGMFFG"
 // if present
  }
  export default config
